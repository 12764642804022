<template>
<div class="modules">
    <div class="transaction-container">
        <div class="summary">
            <h1 class="title"> {{ $t("lang.Transaction") }}</h1>
            <div class="tools">
                
            </div>
        </div>
        <div class="card">
            <!-- <div class="hd">
                <h2 class="title">近期提币记录</h2>
            </div> -->
            <div class="bd">
                <div class="table-wrapper">
                    <div class="table-tools">
                        <el-form :inline="true">
                            <!-- <el-row>
                                <el-col :span=""></el-col>
                                <el-col :span=""></el-col>
                                <el-col :span=""></el-col>
                                <el-col :span=""></el-col>
                            </el-row> -->
                            <el-form-item >
                                <el-select @change ="changeDro" v-model="coinType" size="small" style="width:120px;" placeholder="请选择币种">
                                    <el-option v-for="(item, index) in filterOption" :key="'coin_item_'+index" :value="item.value" :label="item.text">
                                        
                                    </el-option>
                                </el-select>
                            </el-form-item>
                            <!-- <el-form-item label="交易类型">
                                <el-select v-model="queryParams.serialType" size="small" style="width:140px;" placeholder="请选择交易类型"></el-select>
                            </el-form-item> -->
                            <!-- <el-form-item label="交易时间">
                                <el-date-picker v-model="dateRange" style="width:360px" size="small" type="datetimerange" range-separator="至" start-placeholder="开始时间" end-placeholder="结束日期" @change="rangeChange" />
                            </el-form-item>
                            <el-form-item>
                                <el-button size="small" type="primary" @click="query">搜索</el-button>
                            </el-form-item> -->
                        </el-form>
                    </div>
                    <div   class="table-inner"  v-loading="tableLoading"   element-loading-spinner="el-icon-loading" >
                        <el-table :data="dataSource"    >
                            <el-table-column :label="$t('lang.source')" prop="bizDescrVal"></el-table-column>
                            <el-table-column :label="$t('lang.GameID')" prop="gameId"></el-table-column>
                            <el-table-column :label="$t('lang.GameTitle')" prop="gameTitle">
                                <template slot-scope="scope">
                                  <span @click="goOption(scope.row.gameId)" >{{ scope.row.gameTitle }}</span>
                                  <!-- <span >{{ scope.row.revoCion }}</span> -->
                                </template>
                            </el-table-column>
                            <el-table-column :label="$t('lang.typeTransaction')" prop="serialType">
                                <template slot-scope="scope">
                                  <span >{{ scope.row.serialTypeVal }}</span>
                                  <!-- <span >{{ scope.row.revoCion }}</span> -->
                              </template>
                            </el-table-column>
                            <el-table-column :label="$t('lang.amountTransaction')" prop="revoCion"></el-table-column>
                            <el-table-column :label="$t('lang.CreationTime')" prop="createTime"></el-table-column>
                            <!-- <el-table-column label="操作"></el-table-column> -->
                        </el-table>
                        <div class="pagination">
                            <el-pagination
                                @size-change="handleSizeChange"
                                @current-change="handleCurrentChange"
                                :current-page.sync="ipagination.pageNo"
                                :page-size="ipagination.pageSize"
                                layout="prev, pager, next, jumper"
                                :total="ipagination.total">
                            </el-pagination>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
</template>
<script>
import {BASE_GLOB_API} from "@/config.js";
import {axiosGet,axiosPost} from '@/api/api';
import moment from "moment";
import EventBus from '@/utils/EventBus'
export default {
    name:'transaction',
    data(){
        return {
            url:{
                page:BASE_GLOB_API+'/serial/page',
                platform:BASE_GLOB_API+'/kanban/coin/platform',
            },
            dateRange:'',
            queryParams:{},
            dataSource:[],
            ipagination:{
                pageNo:1,
                pageSize:10,
                total:0
            },
            tableLoading:false,
            filterOption:[],
            coinType:0
        }
    },
    mounted(){
        
    },
    created(){
        this.lang = sessionStorage.getItem("Language")
        EventBus.$on('share', value => {
      // 将 A 组件传递过来的数据保存到 B 组件
            console.log("value123",value)
           if(value){
            this.fetchUserPlatform()
           
           }

        })
       
        this.fetchUserPlatform()
       
    },
    beforeDestroy() {
    // 移除监听事件 "share"
       EventBus.$off('share')
    },
    methods:{
        goOption(e){
            console.log("e",e)
            this.$router.push({ name: "nftHistory", query: { tokenID: e } });
        },
        changeDro(e){
          this.coinType =e
          this.init(1,10)
        },
        fetchUserPlatform(){
            axiosGet(this.url.platform).then(res=>{
               if(res.code ==200){
                //  this.filterOption = res.result
                //   let arrDrp =[{fullName:'eth',id:'3'},{fullName:'htl',id:'122'},{fullName:'tey',id:'222'}];
                  let arrayList =res.result,fullNameList =[]
                   arrayList.forEach(item=>{
                    if(item.enabled){
                     let are ={
                        text:item.fullName,
                        value:item.id,
                     }
                     fullNameList.push(are)
                     this.filterOption = fullNameList
                    }
                   });
                   this.coinType = this.filterOption[0].value
                   
                   if(this.$route.params.flowType){
                       this.init(1,10)
                   }else if(this.$route.params.thirdFlowNo){
                    this.initTeam(1,10)
                   }else{
                    this.init(1,10)
                   }
               }else{
                    this.$message.error(res.message);
                }
            })
           
        }, 
        initTeam(pageNo, pageSize){
            let  third = 'allot' +':' + this.$route.params.thirdFlowNo
            // console.log("1",third)
            this.tableLoading = true;
            let params = {
                current:pageNo,
                size:pageSize,
                query: {
                  btPlatformId: this.coinType,
                  thirdFlowNo:third
                }

            }
            params = Object.assign(params, this.queryParams);
            axiosPost(this.url.page, params).then(res=>{
                this.tableLoading = false;
                if(res.success){
                    let data = res.result;
                    this.dataSource = data.records;
                    this.ipagination.pageNo = Number(data.current);
                    this.ipagination.pageSize =Number(data.size);
                    this.ipagination.total =Number(data.total);
                }else{
                    this.$message.error(res.message);
                }
            })
        },
        init(pageNo, pageSize){
            this.tableLoading = true;
            let params = {
                current:pageNo,
                size:pageSize,
                query: {
                  btPlatformId: this.coinType,
                  flowType:this.$route.params.flowType
                }

            }
            params = Object.assign(params, this.queryParams);
            axiosPost(this.url.page, params).then(res=>{
                this.tableLoading = false;
                if(res.success){
                    let data = res.result;
                    this.dataSource = data.records;
                    this.ipagination.pageNo = Number(data.current);
                    this.ipagination.pageSize =Number(data.size);
                    this.ipagination.total =Number(data.total);
                }else{
                    this.$message.error(res.message);
                }
            })
        },
        handleSizeChange(val) {
            //console.log(`每页 ${val} 条`);
        },
        handleCurrentChange(val) {
            //console.log(`当前页: ${val}`);
            this.init(val, this.ipagination.pageSize);
        },
        rangeChange(e){
            console.log("rangeChange:",e)
            if(e!=null){
                this.queryParams.createTimeStart = moment(e[0]).format("YYYY-MM-DD HH:mm:ss");
                this.queryParams.createTimeEnd = moment(e[1]).format("YYYY-MM-DD HH:mm:ss");
            }
        },
        query(){
            this.init(1, this.ipagination.pageSize);
        }
    }
}
</script>
<style lang="less" scoped>
.transaction-container {
    .summary {
        background:#f4f5f6; border-radius:4px; padding:30px; display:flex; justify-content: space-between; align-items: center; margin-bottom:30px;
    }
    .box-wrapper {
        display:flex; justify-content: space-between; align-items: flex-start;
        .box-main {
            flex:1;
            .form-item {

            }
            .form-item-help {
                display:flex; justify-content: space-between; align-items: flex-start; flex-wrap:wrap; margin:10px -5px;
                .meta {
                    width:50%; line-height:23px; padding:5px;
                    .meta-title {color:#999;}
                    .meta-val {color:#333; font-weight:700;}
                }
            }
        }
        .box-side {
            width:300px; min-width:300px; margin-left:50px;
            .mod {
                .bd {
                    .list {
                        .item {
                            display:flex; justify-content: flex-start; align-items: flex-start; padding:4px 0;
                            .item-icon {
                                background:url(~@/assets/img/icon_text.svg) no-repeat center center/contain; width:12px; height:12px; margin:4px 6px 0 0;
                            }
                            .item-text {
                                font-size:14px; color:#666; cursor: pointer;
                                &:hover {
                                    color:#4458fe; text-decoration: underline; opacity: .8;
                                }
                            }
                            &.item-video {
                                .item-icon {
                                    background-image:url(~@/assets/img/icon_video.svg);
                                }
                            }
                        }
                    }
                }
            }
        }
    }
    .card {
        .hd {
            .title {}
        }
        .bd {
            .table-wrapper {
                padding:10px 0;
                .table-tools {
                    text-align: center;
                    .table-tabs {
                        display:flex; justify-content: flex-start; align-items: center;
                        .tab-item {
                            line-height:1; padding:8px 14px; border-radius:4px; background:#f4f5f6; margin:0 10px 10px 0; cursor: pointer;
                            &.active, &:hover {
                                background:#e7eafe; color:#4458fe;
                            }
                        }
                    }
                }
                .pagination {
                    padding:15px 0; text-align:right;
                }
            }
        }
    }
}
</style>